.panel-bold {
  background-color: @panel-default-border;
  border: 0;

  hr {
    background: #fff;
  }

  p {
    color: rgba(255,255,255,.7)
  }

  &.panel-default {
    background-color: @panel-default-border;
  }
  &.panel-primary {
    background-color: @brand-primary;
  }
  &.panel-success {
    background-color: @brand-success;
  }
  &.panel-info {
    background-color: @brand-info;
  }
  &.panel-warning {
    background-color: @brand-warning;
  }
  &.panel-danger {
    background-color: @brand-danger;
  }

}
