// Transparent navbar
.navbar-transparent {
  background-color: @navbar-transparent-bg;
  border-color: @navbar-transparent-border;

  .navbar-brand {
    color: @navbar-transparent-brand-color;
    &:hover,
    &:focus {
      color: @navbar-transparent-brand-hover-color;
      background-color: @navbar-transparent-brand-hover-bg;
    }
  }

  .navbar-text {
    color: @navbar-transparent-color;
  }

  .navbar-nav {
    > li > a {
      color: @navbar-transparent-link-color;

      &:hover,
      &:focus {
        color: @navbar-transparent-link-hover-color;
        background-color: @navbar-transparent-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-transparent-link-active-color;
        background-color: @navbar-transparent-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-transparent-link-disabled-color;
        background-color: @navbar-transparent-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: @navbar-transparent-toggle-border-color;
    &:hover,
    &:focus {
      background-color: @navbar-transparent-toggle-hover-bg;
    }
    .icon-bar {
      background-color: @navbar-transparent-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: @navbar-transparent-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: @navbar-transparent-link-active-bg;
        color: @navbar-transparent-link-active-color;
      }
    }

    @media (max-width: @grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: @navbar-transparent-link-color;
          &:hover,
          &:focus {
            color: @navbar-transparent-link-hover-color;
            background-color: @navbar-transparent-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-transparent-link-active-color;
            background-color: @navbar-transparent-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: @navbar-transparent-link-disabled-color;
            background-color: @navbar-transparent-link-disabled-bg;
          }
        }
      }
    }
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    color: @navbar-transparent-link-color;
    &:hover {
      color: @navbar-transparent-link-hover-color;
    }
  }

  .btn-link {
    color: @navbar-transparent-link-color;
    &:hover,
    &:focus {
      color: @navbar-transparent-link-hover-color;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        color: @navbar-transparent-link-disabled-color;
      }
    }
  }
}
